<!--
* @Description 行业案例-京沪高速
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>
    <div class="con">
      <h1 class="hyalTitle">京沪高速江苏段改扩建BIM协同建设管理平台</h1>
      <div class="conText mb40">
        <p>
          　　京沪高速公路（G2）是中国的一条重要高速公路，是连接中国两个最大城市——北京和上海的重要干线。其中，江苏段跨越了江苏省的南北方向，连接着苏南、苏中、苏北等地区。
        </p>
        <p>
          　　由于近年来中国经济的快速发展，京沪高速公路的交通量不断增加，尤其是节假日和长假期间，车流量大增，严重影响了道路的通行能力。因此，对京沪高速公路江苏段进行改扩建，显得尤为必要。一旦项目完成，将大大提高道路通行能力，缓解交通拥堵，有利于促进沿线地区的经济和社会发展，提高人民群众的出行效率和安全性。
        </p>
      </div>
      <div class="plr60">
        <div class="conImgH600 mb40">
          <img :src="imgs[0].url" alt="" />
        </div>
      </div>
      <div class="conText mb40">
        <p>
          　　京沪高速江苏段改扩建BIM协同建设管理平台是为了更好地管理和监控京沪高速江苏段改扩建项目而建立的一种协同工作平台，采用BIM技术（建筑信息模型）进行建设管理。
        </p>
        <p>
          　　该平台集成了多种技术和工具，包括建筑信息模型、智能化监控系统、GIS（地理信息系统）等，通过实时监控和数据共享，实现了各个部门之间的协同工作和信息共享。
        </p>
      </div>
      <div class="plr60">
        <div class="conImgH600 mb40">
          <img :src="imgs[1].url" alt="" />
        </div>
      </div>
      <div class="conText pb40">
        <p>　　平台的主要功能包括：</p>
        <p>
          　　建筑信息模型：采用BIM技术对项目进行建模和仿真，提高了设计和建设的效率和质量。
        </p>
        <p>
          　　智能化监控系统：通过安装各种传感器和监控设备，对项目进行实时监控，及时发现和处理安全和质量问题。
        </p>
        <p>
          　　GIS系统：采用地理信息系统技术，对项目进行地理空间分析和可视化展示，帮助项目管理人员更好地了解项目的地理环境和情况。
        </p>
        <p>
          　　数据共享和协同工作：通过平台共享数据和信息，实现各个部门之间的协同工作和信息共享，提高项目的管理效率和质量。
        </p>
        <br />
        <p>　　平台还具有以下特点和优势：</p>
        <p>
          　　实现了数字化建设：通过建筑信息模型技术，实现了对项目的数字化建设，包括设计、施工、运营等各个阶段。这不仅提高了建设效率，也降低了建设成本，同时提高了项目的质量和安全性。
        </p>
        <p>
          　　实现了智能化管理：通过智能化监控系统，实现了对项目的实时监控和预警，及时发现和处理各种问题。这有助于提高项目的管理水平和效率，降低了安全风险，提高了质量保障。
        </p>
        <p>
          　　实现了协同工作和信息共享：通过数据共享和协同工作，实现了各个部门之间的信息共享和协同工作，提高了项目管理的效率和质量。
        </p>
        <p>
          　　支持多种技术和工具：该平台支持多种技术和工具，包括建筑信息模型、智能化监控系统、GIS等，可以根据实际需要进行灵活配置和调整，满足不同阶段的需求。
        </p>
        <p>
          　　平台采用了先进的BIM技术和智能化监控系统，实现了数字化建设和智能化管理，提高了项目的管理水平和效率，有助于促进建设行业的数字化和智能化发展。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          url: require("../../../assets/images/industryImg/edff03eb7725a6155dfde55cc48185a4@2x.png"),
        },

        {
          url: require("../../../assets/images/industryImg/W020211231398298104137Bf@2x(1).png"),
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.aboutContainer {
  background: #ffffff;
  .top {
    width: 100%;
    height: 400px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/industryImg/5fc6-2c9e6c6658af68005233c6c04d31f5701@2x(4).png");

    background-size: 100% 100%;

    background-repeat: no-repeat;
  }

  .con {
    width: 1200px;
    text-align: center;
    // padding: 0 360px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
